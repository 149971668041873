<template>
  <!-- 发货 -->
  <div class="wrapper">
    <admin-title title="发货"></admin-title>

    <div class="header">
      <el-tabs v-model="activeName">
        <el-tab-pane
          v-if="
            deliveryType.indexOf(1) > -1 && enableMaterialDelivery && type == 1
          "
          label="物流配送"
          name="1"
        ></el-tab-pane>
        <el-tab-pane
          v-if="
            deliveryType.indexOf(2) > -1 && enableMaterialDelivery && type == 1
          "
          label="门店自提"
          name="2"
        ></el-tab-pane>
        <el-tab-pane
          v-if="
            deliveryType.indexOf(3) > -1 && enableVirtualDelivery && type == 2
          "
          label="虚拟发货"
          name="3"
        ></el-tab-pane>
      </el-tabs>
      <div class="tip-hit" v-if="tip">
        <span class="tip"
          ><i class="el-icon-warning"></i> 该用户与<span
            style="font-weight: 700; color: #000"
            >{{ tip }}</span
          >库中数据匹配，请复核后谨慎操作！</span
        >
      </div>
    </div>
    <!-- 普通订单 -->
    <DeliveryPt v-if="activeName == 1"></DeliveryPt>
    <!-- 门店订单 -->
    <DeliveryStore v-if="activeName == 2"></DeliveryStore>
    <!-- 虚拟发货 -->
    <DeliveryVirtual v-if="activeName == 3"></DeliveryVirtual>
  </div>
</template>

<script>
import { hitAPI } from "./api";
import { dict_order_type } from "@/enum/dict/index.js";
import DeliveryPt from "./delivery-putong.vue";
import DeliveryStore from "./delivery-store.vue";
import DeliveryVirtual from "./delivery-virtual.vue";
import FileUpload from "@/components/file-upload/index.vue";
import SelectDict from "@/views/components/select-dict.vue";
export default {
  name: "Delivery",
  components: { DeliveryPt, DeliveryStore, DeliveryVirtual },
  data() {
    return {
      activeName: "1",
      dict_order_type,
      deliveryType: [],
      enableVirtualDelivery: false,
      enableMaterialDelivery: false,
      type: "",
      tip: "",
    };
  },
  watch: {
    deliveryType: {
      immediate: true,
      handler(newV, oldV) {
        if (
          this.deliveryType.indexOf(3) > -1 &&
          this.enableVirtualDelivery &&
          this.type == 2
        ) {
          this.activeName = "3";
        }
        if (
          this.deliveryType.indexOf(2) > -1 &&
          this.enableMaterialDelivery &&
          this.type == 1
        ) {
          this.activeName = "2";
        }
        if (
          this.deliveryType.indexOf(1) > -1 &&
          this.enableMaterialDelivery &&
          this.type == 1
        ) {
          this.activeName = "1";
        }
      },
    },
  },
  mounted() {
    this.deliveryType = JSON.parse(localStorage.getItem("deliveryTypeList"));
    this.enableMaterialDelivery = JSON.parse(
      localStorage.getItem("enableMaterialDelivery"),
    );
    this.enableVirtualDelivery = JSON.parse(
      localStorage.getItem("enableVirtualDelivery"),
    );
    this.type = JSON.parse(localStorage.getItem("btn_type"));
    this.hit();
  },

  methods: {
    // 撞库
    async hit() {
      let arr = [];
      // const { rosterLevelList } = await hitAPI(this.$route.params.id)
      const rosterLevelList = JSON.parse(
        localStorage.getItem("rosterLevelList"),
      );
      rosterLevelList.forEach((item) => {
        if (item == 2) {
          arr.push("黑名单");
        } else if (item == 3) {
          arr.push("灰名单");
        } else if (item == 1) {
          arr.push("白名单");
        }
      });
      this.tip = arr.join("、");
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          handleDelieryAPI(this.formData).then(() => {
            this.$message.success("发货成功");
            //
            setTimeout(() => {
              this.closePage();
            }, 1000);
          });
        }
      });
    },
    emitAwindow() {
      //window.opener 获取父页面的window元素
      //判断A窗口有没有window.opener和getBpageList是不是个方法
      if (window.opener && window.opener.getBpageList) {
        window.opener.getBpageList();
      } else {
        //window.opener.frames[0] 获取到的window对象
        window.opener.frames[0].getBpageList();
      }
    },
    closePage() {
      this.emitAwindow();
      window.close();
      localStorage.removeItem("deliveryTypeList");
      localStorage.removeItem("rosterLevelList");
      localStorage.removeItem("enableMaterialDelivery");
      localStorage.removeItem("enableVirtualDelivery");
      localStorage.removeItem("btn_type");
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .header {
    position: relative;
    .tip-hit {
      position: absolute;
      width: 500px;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: #f9d7d9;
      padding: 8px 5px;
      border-radius: 2px;
      font-weight: 600;
      i {
        color: #e34d59;
        font-size: 16px;
        vertical-align: baseline;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 148px;
    text-align: center;
  }

  .avatar {
    width: 148px;
    height: 148px;
    display: block;
  }

  .goods-sku,
  .baseInfo-2 {
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
    }

    .avatar {
      width: 60px;
      height: 60px;
      display: block;
    }
  }
}
</style>
