var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('admin-title',{attrs:{"title":"发货"}}),_c('div',{staticClass:"header"},[_c('el-tabs',{model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[(
          _vm.deliveryType.indexOf(1) > -1 && _vm.enableMaterialDelivery && _vm.type == 1
        )?_c('el-tab-pane',{attrs:{"label":"物流配送","name":"1"}}):_vm._e(),(
          _vm.deliveryType.indexOf(2) > -1 && _vm.enableMaterialDelivery && _vm.type == 1
        )?_c('el-tab-pane',{attrs:{"label":"门店自提","name":"2"}}):_vm._e(),(
          _vm.deliveryType.indexOf(3) > -1 && _vm.enableVirtualDelivery && _vm.type == 2
        )?_c('el-tab-pane',{attrs:{"label":"虚拟发货","name":"3"}}):_vm._e()],1),(_vm.tip)?_c('div',{staticClass:"tip-hit"},[_c('span',{staticClass:"tip"},[_c('i',{staticClass:"el-icon-warning"}),_vm._v(" 该用户与"),_c('span',{staticStyle:{"font-weight":"700","color":"#000"}},[_vm._v(_vm._s(_vm.tip))]),_vm._v("库中数据匹配，请复核后谨慎操作！")])]):_vm._e()],1),(_vm.activeName == 1)?_c('DeliveryPt'):_vm._e(),(_vm.activeName == 2)?_c('DeliveryStore'):_vm._e(),(_vm.activeName == 3)?_c('DeliveryVirtual'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }